<template>
    <div class="tophead_bar">
        <div class="container">
            <Social class="social-list" />
            <div class="htright clearfix">
                <ul class="clearfix"> 
                    <li class="clearfix">
                        <div class="loginlink"> 
                            <a href="#" @click.prevent="setLanguage(item.code, true)" v-for="(item, index) of languageData" :key="index"> 
                                {{ item.language }} <span v-if="index < 2">|</span>
                            </a>                                                            
                        </div>
                    </li>
                    <li class="clearfix">
                        <div class="loginlink" v-if="!storeToken"> 
                            <router-link to="/account/login">{{ pageTitle('login') }}</router-link> 
                            <span>|</span>
                            <router-link to="/account/registration">{{ pageTitle('sign_up') }}</router-link>
                        </div>
                        <div class="loginlink" v-else> 
                            <router-link to="/user/dashboard">{{ pageTitle('dashboard') }}</router-link>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import Social from './Social'
import Translation from '../../public/translation.json'

export default {
    components: {Social},
    data() {
        return {
            storeToken: this.$store.getters.storeToken || '', 
            languageData: [],
            defaultLang: localStorage.getItem('_store_lang') || 'en',
        }
    },
    methods: {        
        pageTitle(field) {
            return Translation.pagetitle[this.defaultLang][field]
        },
        getLanguage(){
            this.$http.get(`languages`).then(res => {
                if(res.data.status === true) {
                    this.languageData = res.data.data
                    if(!localStorage.getItem('_store_lang')) {
                        this.languageData.map(item=>{
                            if(item.default_language === 'Y') {
                                this.defaultLanguage = item.code
                                this.setLanguage(this.defaultLanguage, false)
                                return false
                            }
                        })
                    }
                }
            })
            .catch(err => {
                console.log(err)
            })
        },
        setLanguage(lang, reload){
            if(!lang) return;
            this.defaultLanguage = lang
            localStorage.setItem('_store_lang', this.defaultLanguage)
            if(reload === true){
                //this.$router.push('/home')
                //location.reload()
                window.location.href = '/'
            }
        }
    },
    created: function(){
        this.getLanguage()
    }
}
</script>

<style scoped>
.loginlink span{cursor: pointer;}
</style>