<template>
    <form id="subscribe-form" method="post" @submit.prevent="subscribeFormSubmit">
        <div class="form-group form-box" id="email_block">
            <input type="email" :placeholder="placeholderText('email')" name="email" maxlength="50" v-model.trim="$v.formData.email.$model">
            <p class="form-error text-danger" v-if="!formStatus && $v.formData.email.$error && !$v.formData.email.required">{{ validationRequired('email.required') }}</p>
            <p class="form-error text-danger" v-if="!formStatus && $v.formData.email.$error && !$v.formData.email.email">{{ validationRequired('email.invalid') }}</p>
        </div>                           
        <div class="form-group mb-0 clearfix">
            <button type="submit" class="btn-md gradient-btn btn-theme float-left" :data-title="button">{{ button }}</button>
        </div>         
        <div class="resMssg text-center mt-3" v-html="formResponse"></div>
    </form>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import Translation from '../../../public/translation.json'

export default {
    data() {
        return {
            defaultLang: localStorage.getItem('_store_lang') || 'en',
            formData: {
                email: ''
            },
            formResponse: '',
            formStatus: true
        }
    },
    computed: {
        button() {
            return Translation.button[this.defaultLang].submit
        }
    },
    validations: {
        formData: {
            email: {required,email},
        }
    },
    methods: {
        placeholderText(field){
            return Translation.placeholder[this.defaultLang][field]
        },
        validationRequired(field){
            return Translation.validation[this.defaultLang][field]
        },
        subscribeFormSubmit(){
            this.formStatus = false
            this.$v.$touch()
            if(!this.$v.$invalid){
                document.querySelector('.resMssg').style.display = 'block'
                this.formResponse = ''
                this.$http.post(`/subscriber`, this.formData )
                .then(res=>{
                    if(typeof res !== 'undefined' && res.status === 200){
                        if(res.data.status === true){                            
                            this.formResponse =`<span class="alert alert-success">${res.data.message}</span>`    
                            this.formData.email = ''
                            this.formStatus = true
                        }                
                        else
                            this.formResponse =`<span class="alert alert-danger">${res.data.error.email}</span>`
                    }
                    
                }).catch(err => {
                    //console.log('err', err.response);
                    this.formResponse =`<span class="alert alert-danger">${err.response.data.error}</span>`
                })

                this.togellingResponse();
            }
        },
        togellingResponse(){
            setTimeout(()=>{
                document.querySelector('.resMssg').style.display = 'none'
            }, 5000)
        }
    }
    
}
</script>