<template>
    <section>
        <Header/>
        <transition name="fade">
			<router-view></router-view>
		</transition>
        <WhatsApp/>
        <Footer/>
        <Copyright/>
    </section>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Copyright from "@/components/Copyright.vue";
import WhatsApp from "@/components/WhatsApp.vue";


export default {
    name: 'App',
    components: {
        Header,
        Footer,
        Copyright,
        WhatsApp
	},
    created: function(){        
		
	}
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>