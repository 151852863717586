<template>
    <header class="top-header">
        <TopHeader/>
        <nav class="navbar header-nav navbar-expand-lg">
            <div class="container-fluid">
                <router-link class="navbar-brand logoband" :to="this.$baseURL">
                    <img :src="`${this.$baseURL}images/logo.png`" alt="image">
                </router-link>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-wd" aria-controls="navbar-wd" aria-expanded="false" aria-label="Toggle navigation">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <!-- <MobileMenu :linkLists="getLinklists" /> -->


                <div class="collapse navbar-collapse justify-content-end" id="navbar-wd">            
                    <ul class="navbar-nav">
                        <li v-for="(data, index) of getLinklists" :key="index" class="menuclick">
                            <router-link class="nav-link " :to="data.path">
                                {{ data.name }}
                                <i v-if="data.child" class="icon-material-outline-keyboard-arrow-down"></i>
                            </router-link>
                            <ul class="sub-menu" v-if="data.child">
                                <li v-for="(child, indx) of data.child" :key="indx" class="menuclick">
                                    <router-link :to="child.path">{{ child.name }}</router-link>
                                </li>
                            </ul>
                        </li>                        
                    </ul>
                    <!-- <div class="enrollbtn">
                        <a class="nav-link " :href="`${$baseURL}user/dashboard`" v-if="storeToken">
                            <i class="uil uil-clipboard-alt"></i> {{ getDashboard[0].title }}
                        </a>
                        <router-link class="nav-link " to="/account/login" v-else>
                            <i class="uil uil-clipboard-alt"></i> {{getEnrollNow[0].title}}
                        </router-link>
                    </div> -->
                   
                </div>                
            </div>
        </nav>
    </header>
</template>

<script>
import 'bootstrap';
import TopHeader from '@/components/TopHeader'

export default {
    components: {
        TopHeader
    },
    data() {
        return {         
            storeToken: this.$store.getters.storeToken || '',   
            defaultLang: localStorage.getItem('_store_lang') || 'en',            
            linklists: [
                {
                    lang: 'en', 
                    items: [                    
                        {name: 'Home', slug: 'home', path: '/'},
                        {name: 'About', path: '#', child: [
                            {name: 'Mission', path: '/about/mission'},
                            {name: 'Vision', path: '/about/vision'},
                            {name: 'Values', path: '/about/values'}
                        ]},
                        {name: 'Courses & Activities', slug: 'courses', path: '/courses'},
                        {name: 'Blog', slug: 'blog', path: '/blog'},
                        {name: 'Gallery', slug: 'gallery', path: '/gallery'},
                        {name: 'Forum', slug: 'forum', path: '/forum'},
                        {name: 'Contact Us', slug: 'contact-us', path: '/contact-us'}        
                    ]
                },
                {
                    lang: 'ar', 
                    items: [                    
                        {name: 'الصفحة الرئيسية', slug: 'home', path: '/'},
                        {name: 'من نحن', path: '#', child: [
                            {name: 'مهمة', path: '/about/mission'},
                            {name: 'رؤية', path: '/about/vision'},
                            {name: 'القيم', path: '/about/values'}
                        ]},
                        {name: 'الموادّ والأنشطة', slug: 'courses', path: '/courses'},
                        {name: 'مقالات', slug: 'blog', path: '/blog'},
                        {name: 'معرض الصور', slug: 'gallery', path: '/gallery'},
                        {name: 'المنتدى', slug: 'forum', path: '/forum'},
                        {name: 'اتصل بنا', slug: 'contact-us', path: '/contact-us'}   
                    ]
                },
                {
                    lang: 'fr', 
                    items: [                    
                        {name: 'Accueil', slug: 'home', path: '/'},
                        {name: 'À PROPOS DE NOUS', path: '#', child: [
                            {name: 'Mission', path: '/about/mission'},
                            {name: 'Vision', path: '/about/vision'},
                            {name: 'Valeurs', path: '/about/values'}
                        ]},
                        {name: 'COURS & ACTIVITÉS', slug: 'courses', path: '/courses'},
                        {name: 'BLOGUE', slug: 'blog', path: '/blog'},
                        {name: 'Galerie', slug: 'gallery', path: '/gallery'},
                        {name: 'Forum', slug: 'forum', path: '/forum'},
                        {name: 'Nous contacter', slug: 'contact-us', path: '/contact-us'}    
                    ]
                }
            ],
            enrollNow: [
                {lang: 'en', title: 'Enroll Now'},
                {lang: 'ar', title: 'تسجيل الآن'},
                {lang: 'fr', title: 'Inscrivez-vous maintenant'}
            ],
            dashboard: [
                {lang: 'en', title: 'Dashboard'},
                {lang: 'ar', title: 'لوحة القيادة'},
                {lang: 'fr', title: 'Tableau de bord'}
            ]
        }
    },
    computed: {
        getEnrollNow() {
            return this.enrollNow.filter(res => {
                return (res.lang == this.defaultLang) 
            })
        },
        getDashboard() {
            return this.dashboard.filter(res => {
                return (res.lang == this.defaultLang) 
            })
        },
        getLinklists() {
            let menuData = [];
            this.linklists.map(res => {
                if(res.lang == this.defaultLang) {
                    menuData = res.items
                    return false
                }
            })
            return menuData;
        }
    },
    methods: {
        expandMenu() {
            document.querySelector('#navbar-wd').classList.add('collapse', 'show')
        },
        stickyMenu(){
            if(document.documentElement.scrollTop > 100)
                document.querySelector('.top-header').classList.add('fixed-menu')
            else                
                document.querySelector('.top-header').classList.remove('fixed-menu')
        },
        appendMenu(){
            if(this.$store.state.configuration.gallery_show == 'public' || this.$store.state.auth.token) {            
                this.linklists.every(item=> {             
                    if(this.defaultLang == 'en') {
                        this.linklists[0].items.splice(3, 0, {name: 'Gallery', slug: 'gallery', path: '/gallery'});
                        return false;
                    }
                    else if(this.defaultLang == 'ar') {
                        this.linklists[1].items.splice(3, 0, {name: 'صالة عرض', slug: 'gallery', path: '/gallery'})
                        return false;
                    }
                    else if(this.defaultLang == 'fr') {
                        this.linklists[2].items.splice(3, 0, {name: 'Galerie', slug: 'gallery', path: '/gallery'})
                        return false;
                    }
                })
            }
        }
    },
    created: function() {        
        // this.appendMenu();
    },
    mounted: function() {
        if(document.body.clientWidth <= 480){
            document.querySelectorAll('.menuclick').forEach(el =>{
                el.addEventListener('click', ()=>{
                    document.querySelector('.navbar-toggler').click();
                });
            });
        }
        window.addEventListener('scroll', this.stickyMenu); 
    },
    beforeDestroy: function(){
        window.removeEventListener('scroll', this.stickyMenu);
    },
    watch: {
        // "$store.state.configuration.gallery_show": function() {
        //     this.appendMenu();
        // }
    }
}
</script>