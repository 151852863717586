<script>
export default {
    methods: {
        toggleScrollTop(){
            if(document.documentElement.scrollTop >= 550)
                document.querySelector('a#scroll-to-top').style.display = 'block'
            else
                document.querySelector('a#scroll-to-top').style.display = 'none'
        },
        scrolltoTopEl(){
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    },
    mounted: function(){
        window.addEventListener('scroll', this.toggleScrollTop); 
    },
    beforeDestroy(){
        window.removeEventListener('scroll', this.toggleScrollTop); 
    }
}
</script>

<template>
    <a href="#" id="scroll-to-top" class="hvr-radial-out" style="" @click.prevent="scrolltoTopEl"><i class="icon-material-outline-keyboard-arrow-up"></i></a>
</template>