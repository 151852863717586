<template>
    <footer class="footer-box">
        <div class="container">		
            <div class="row">		   
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                    <div class="footer_blog">
                        <div class="full margin-bottom_30">
                            <img :src="`${$baseURL}images/logo.png`" :alt="siteTitle" />
                            <!-- <img :src="`${$baseURL}images/gis_logo.png`" :alt="siteTitle" /> -->
                            <a href="//www.dmca.com/Protection/Status.aspx?ID=82a1e0c2-33d3-429f-9afe-256e5f7f3057" title="DMCA.com Protection Status" class="dmca-badge"> <img src ="https://images.dmca.com/Badges/dmca_protected_sml_120n.png?ID=82a1e0c2-33d3-429f-9afe-256e5f7f3057"  alt="DMCA.com Protection Status" /></a>  
                        </div>
                        <div class="full" v-html="tagline"></div>
                    </div>
                </div>
                
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                    <div class="footer_blog footer_menu">
                        <h3 v-html="column2[0].title"></h3>
                        <ul>
                            <li v-for="(data, index) of getLinklists" :key="index">
                                <router-link  class="nav-link" :to="data.path">{{ data.name }}</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                    
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                    <div class="footer_blog full">
                        <h3 v-html="column3[0].title"></h3>
                        <p v-html="newsletterText[0].title"></p>
                        <div class="newsletter_form">
                            <Newsletter/>
                        </div>
                    </div>
                </div>	 
                
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                    <div class="footer_blog full">
                        <h3 v-html="column4[0].title"></h3>
                        <ul class="full">
                            <li><i class="icon-material-outline-location-on"></i><span v-html="siteAddress"></span></li>
                            <li><i class="icon-material-outline-email"></i><span>{{ siteEmail }}</span></li>
                            <li><i class="icon-feather-phone"></i><span>{{ sitePhone }}</span></li>
                        </ul>                       
                    </div>
                     <div class="rs-footer">
                        <div class="footer-share">
                            <Social/>
                        </div>
                    </div>
                </div>                
            </div>		
        </div>
        <ScrollTop/>
    </footer>
</template>

<script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script>
<script>
import Newsletter from '@/components/Contact/Subscribe'
import ScrollTop from '@/components/ScrollTop'
import Social from './Social'

export default {
    components: {
        Newsletter, ScrollTop, Social
    },
    data(){
        return {
            heading: [
                {
                    column2: [
                        {lang: 'en', 'title': `Quick links`},
                        {lang: 'ar', 'title': `روابط سريعة`},
                        {lang: 'fr', 'title': `Liens rapides`}
                    ],
                    column3: [
                        {lang: 'en', 'title': `Newsletter`},
                        {lang: 'ar', 'title': `النشرة الإخبارية`},
                        {lang: 'fr', 'title': `Bulletin`}
                    ],
                    column4: [
                        {lang: 'en', 'title': `Contact us`},
                        {lang: 'ar', 'title': `اتصل بنا`},
                        {lang: 'fr', 'title': `Nous contacter`}
                    ]
                }
            ],
            defaultLang: localStorage.getItem('_store_lang') || 'en',
            newsletter: [
                {lang: 'en', 'title': `Subscribe our newsletter to get latest updates.`},
                {lang: 'ar', 'title': `اشترك في النشرة الإخبارية لدينا للحصول على آخر التحديثات.`},
                {lang: 'fr', 'title': `Abonnez-vous à notre newsletter pour recevoir les dernières mises à jour.`}
            ],
            linklists: [
                {
                    lang: 'en', 
                    items: [                    
                        {name: 'Mission', slug: 'mission', path: '/about/mission'},
                        {name: 'Vision', slug: 'vision', path: '/about/vision'},
                        {name: 'Values', slug: 'values', path: '/values'},
                        {name: 'Contact Us', slug: 'contact-us', path: '/contact-us'}        
                    ]
                },
                {
                    lang: 'ar', 
                    items: [                    
                        {name: 'مهمة', slug: 'mission', path: '/about/mission'},                        
                        {name: 'رؤية', slug: 'vision', path: '/about/vision'},
                        {name: 'قيم', slug: 'values', path: '/values'},
                        {name: 'اتصل بنا', slug: 'contact-us', path: '/contact-us'},
                    ]
                },
                {
                    lang: 'fr', 
                    items: [                    
                        {name: 'Mission', slug: 'mission', path: '/about/mission'},
                        {name: 'Vision', slug: 'vision', path: '/about/vision'},
                        {name: 'Valeurs', slug: 'values', path: '/values'},
                        {name: 'Nous contacter', slug: 'contact-us', path: '/contact-us'}    
                    ]
                }
            ],
        }
    },
    created: function(){
        //console.log(this.heading[0].column2)
    },
    computed: {
        getLinklists() {
            let menuData = [];
            this.linklists.map(res => {
                if(res.lang == this.defaultLang) {
                    menuData = res.items
                    return false
                }
            })
            return menuData;
        },
        newsletterText() {
            return this.newsletter.filter(item=> {
                return (item.lang === this.defaultLang) ? item : ''
            })
        },
        column2() {
            return this.heading[0].column2.filter(item => {
                return (item.lang === this.defaultLang) ? item : ''
            })
        },
        column3() {
            return this.heading[0].column3.filter(item => {
                return (item.lang === this.defaultLang) ? item : ''
            })
        },
        column4() {
            return this.heading[0].column4.filter(item => {
                return (item.lang === this.defaultLang) ? item : ''
            })
        },
        siteTitle(){
            return  this.$store.getters.siteTitle
        },
        siteEmail(){
            return  this.$store.getters.siteEmail
        },
        sitePhone(){
            return  this.$store.getters.sitePhone
        },
        siteAddress(){
            return this.$store.getters.siteAddress
        },
        tagline(){
            return this.$store.state.configuration.tagline
        }
    }
}
</script>
