<template>
    <div class="footer_bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <p class="crp" v-html="getCopyright[0].message"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            defaultLang: localStorage.getItem('_store_lang') || 'en',  
            copyright: [
                {lang: 'en', message: '&copy; Copyrights '+ new Date().getFullYear() +' How Of What. All rights reserved.'},
                {lang: 'ar', message: '&copy; حقوق النشر 2021 كيف ماذا. كل الحقوق محفوظة.'},
                {lang: 'fr', message: '&copy; Copyrights '+ new Date().getFullYear() +' Comment de quoi. Tous les droits sont réservés.'},
            ]
        }
    },
    computed: {
        getCopyright() {
            return this.copyright.filter(res => {
                return (res.lang == this.defaultLang) 
            })
        }
    }
}
</script>